<script>
import Icon from '../Icon';

const colors = ['black', 'blue', 'red', 'green', 'yellow', 'gray', 'custom'];

export default defineNuxtComponent({
  name: 'Label',
  components: { Icon },
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: 'blue',
      validator(value) {
        return colors.includes(value);
      },
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    colors,
  }),
  computed: {
    mainClasses() {
      return this.color === 'custom'
        ? this.customColor()
        : {
            [`label-${this.color}`]: true,
          };
    },
    closeIconClasses() {
      return {
        [`close-icon-${this.color}`]: true,
      };
    },
  },
  methods: {
    emitEvent() {
      this.$emit('remove', this.label);
    },
    customColor() {
      if (this.label === 'Closed') {
        return {
          [`label-gray`]: true,
        };
      }
      else if (this.label === 'Signed in') {
        return {
          [`label-green`]: true,
        };
      }
      else {
        return {
          [`label-black`]: true,
        };
      }
    },
  },
});
</script>

<template>
  <div
    class="flex-direction-row relative flex items-center justify-center rounded-full px-2 py-1 text-center text-sm font-medium shadow"
    :class="mainClasses"
  >
    <span
      class="mx-2 inline-block truncate align-middle"
      :title="__(label)"
      data-tooltip-position="bottom"
    >
      {{ __(label) }}
    </span>
    <div
      v-if="removable"
      class="relative z-1 rounded-full text-xs"
      :class="closeIconClasses"
      @click.stop="emitEvent"
    >
      <Icon name="remove" />
    </div>
  </div>
</template>

<style>
.label-black {
  @apply bg-neutral-3 text-icon-black;
}
.label-blue {
  @apply bg-primary-100 text-primary;
}
.label-red {
  @apply bg-icon-coral-200 text-error;
}
.label-green {
  @apply bg-success-50 text-success;
}
.label-yellow {
  @apply bg-warning-200 text-warning;
}
.label-gray {
  @apply bg-neutral-3 text-neutral-2;
}
.close-icon-black {
  @apply bg-neutral-3-600;
}
.close-icon-blue {
  @apply bg-primary-200;
}
.close-icon-red {
  @apply bg-error-300;
}
.close-icon-green {
  @apply bg-success-100;
}
.close-icon-yellow {
  @apply bg-warning-300;
}
.close-icon-gray {
  @apply bg-neutral-3-600;
}
</style>
